import CdnImage from 'components/util/CdnImage'
// import CloudinaryImage from 'components/util/CloudinaryImage'
import { getCognitoId } from 'components/util/UserId'
import { Field, Formik, useFormikContext } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import Clock from '../../../images/Clock.svg'
import LinkButton, { LinkButtonProp } from '../common/button'

interface Props {
  topBanner: string
  backgroundColor: string
  buttonEnabled: boolean
  buttonProp: LinkButtonProp
  mediaBackdropColor: string
  formBackgroundColor: string
  preFooterTextHeadlineOne: string
  preFooterTextHeadlineTwo: string
  preFooterTextOne: string
  desktopImage: string
  builderDesktopImage: any
  preFooterDesktopImgAltText: string
  mobileImage: string
  builderMobileImage: any
  preFooterMobileTextImgAltText: string
  widgetBackgroundColor: string
  widgetType: WidgetType
  widgetTags: string
  showRating: boolean
  ratingTextBold: string
  ratingTextNormalOne: string
  ratingTextNormalTwo: string
  ratingIcon: string
  builderRatingIcon: any
  coupon: string
}

const DogNamePreFooter = ({
  topBanner,
  backgroundColor,
  buttonEnabled,
  buttonProp,
  mediaBackdropColor,
  formBackgroundColor,
  preFooterTextHeadlineOne = '',
  preFooterTextHeadlineTwo = '',
  preFooterTextOne = '',
  desktopImage,
  builderDesktopImage,
  preFooterDesktopImgAltText = '',
  mobileImage,
  builderMobileImage,
  preFooterMobileTextImgAltText = '',
  widgetBackgroundColor,
  widgetType,
  widgetTags,
  showRating,
  ratingTextBold = '',
  ratingTextNormalOne = '',
  ratingTextNormalTwo = '',
  ratingIcon,
  builderRatingIcon,
  coupon = ''
}: Props) => {
  const formikRef = React.useRef<any>()

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const dogDetailsPage = 'dog-details'

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const { username } = await getCognitoId()

        if (username) {
          setIsLoggedIn(true)
        }
      } catch (e) {
        console.log(e)
      }
    }

    authenticateUser()
  }, [isLoggedIn])

  useEffect(() => {
    const trustWidget = document.getElementById('trust-pilot-micro-combo')
    window.Trustpilot?.loadFromElement(trustWidget)
  }, [widgetType])

  const renderDesktopImage = () => {
    if (desktopImage?.public_id) {
      return (
        <CdnImage
          className="index-2 height-specifier relative w-full h-full rounded-3xl object-cover"
          url={builderDesktopImage}
          alt={preFooterDesktopImgAltText}
          steps={[1000]}
        />

        // <>
        //   {builderDesktopImage ? (
        //     <CdnImage
        //       className="index-2 height-specifier relative w-full h-full rounded-3xl object-cover"
        //       url={builderDesktopImage}
        //       alt={preFooterDesktopImgAltText}
        //       steps={[1000]}
        //     />
        //   ) : (
        //     <CloudinaryImage
        //       className="index-2 height-specifier relative w-full h-full rounded-3xl object-cover"
        //       publicId={desktopImage?.public_id}
        //       alt={preFooterDesktopImgAltText}
        //       steps={[1000]}
        //     />
        //   )}
        // </>
      )
    }
  }

  const renderMobileImage = () => {
    if (mobileImage?.public_id) {
      return (
        <CdnImage
          className="index-2 relative h-full w-full object-cover"
          url={builderMobileImage}
          alt={preFooterMobileTextImgAltText}
          steps={[600]}
        />

        // <>
        //   {builderMobileImage ? (
        //     <CdnImage
        //       className="index-2 relative h-full w-full object-cover"
        //       url={builderMobileImage}
        //       alt={preFooterMobileTextImgAltText}
        //       steps={[600]}
        //     />
        //   ) : (
        //     <CloudinaryImage
        //       className="index-2 relative h-full w-full object-cover"
        //       publicId={mobileImage?.public_id}
        //       alt={preFooterMobileTextImgAltText}
        //       steps={[600]}
        //     />
        //   )}
        // </>
      )
    }
  }

  const renderRatingIcon = () => {
    return (
      <CdnImage
        className="index-2 relative inline-block h-3 w-3"
        url={builderRatingIcon}
        alt={'Dog bowl'}
        steps={[700]}
      />

      // <>
      //   {builderRatingIcon ? (
      //     <CdnImage
      //       className="index-2 relative inline-block h-3 w-3"
      //       url={builderRatingIcon}
      //       alt={'Dog bowl'}
      //       steps={[700]}
      //     />
      //   ) : (
      //     <CloudinaryImage
      //       className="index-2 relative inline-block h-3 w-3"
      //       publicId={ratingIcon?.public_id}
      //       alt={'Dog bowl'}
      //       steps={[700]}
      //       loading={'eager'}
      //     />
      //   )}
      // </>
    )
  }

  const getWidget = () => {
    return (
      <div>
        {/* <!-- TrustBox widget - Micro Combo --> */}
        <div
          className="trustpilot-widget px-8 py-4"
          data-locale="en-GB"
          data-template-id="5419b6ffb0d04a076446a9af"
          data-businessunit-id="5af423d4a3303b0001bf47c1"
          data-style-height="20px"
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
          data-font-family="Montserrat"
          data-text-color="#004355"
          data-tags={widgetTags ? widgetTags : ''}
          id="trust-pilot-micro-combo"
        >
          <a href="https://uk.trustpilot.com/review/differentdog.com" target="_blank" rel="noreferrer noopener">
            <StaticImage objectFit="contain" className="w-full" src="../../../images/trustpilot.png" alt="Trustpilot" />
          </a>
        </div>
        {/* <!-- End TrustBox widget --> */}
      </div>
    )
  }

  const _renderCTAButton = () => {
    if (!buttonEnabled) {
      return (
        <button
          className={`mx-auto flex w-10/12 items-center justify-center rounded-full border border-transparent bg-[#006685] px-5 py-3 text-base font-medium text-[#FFFFFF] md:mt-8 md:w-96 md:text-lg lg:my-16 lg:w-3/5`}
          onClick={() => {
            let eventName = isLoggedIn
              ? 'dog_name_component_pre_footer_cta_submit_logged_in'
              : 'dog_name_component_pre_footer_cta_submit'

            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', eventName, {
                page: 'home_page'
              })
            }
            if (isLoggedIn) {
              navigate('/account/')
            } else {
              window.location.href = `/signup/${dogDetailsPage}/${coupon ? `?coupon=${coupon}` : ''}`
            }
          }}
        >
          {isLoggedIn ? `${buttonProp?.loggedInButtonText}` : `${buttonProp?.buttonText}`}
        </button>
      )
    }

    return null
  }

  const renderDogName = () => {
    return (
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: ''
        }}
        onSubmit={async values => {
          let eventName = isLoggedIn
            ? 'dog_name_component_pre_footer_submit_logged_in'
            : 'dog_name_component_pre_footer_submit'

          if (!values.name) {
            let url = `/signup/${dogDetailsPage}/`
            if (coupon) url += `?coupon=${coupon}`
            window.location.href = url
            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', eventName, {
                page: 'home_page'
              })
            }
          } else {
            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', eventName, {
                page: 'home_page',
                dog_name: values.name
              })
            }
            let url = `/signup/${dogDetailsPage}/?d=${encodeURI(btoa(values.name))}`
            if (coupon) url += `&coupon=${coupon}`
            window.location.href = url
          }
        }}
        validateOnMount={false}
      >
        {props => {
          return (
            <>
              <FormikErrorFocus
                formik={useFormikContext()}
                offset={0}
                align={'middle'}
                focusDelay={1000}
                ease={'linear'}
                duration={1000}
              />
              <div
                className="mx-auto mt-4 w-4/5 rounded-3xl p-4"
                style={{ backgroundColor: formBackgroundColor || '#F9F5F1' }}
              >
                <span className="text-grey-500 mb-4 flex justify-center whitespace-nowrap text-center font-serif text-lg md:text-xl">
                  Who are we cooking for today?
                </span>
                <div className="my-4 flex w-full items-center justify-center">
                  <Field
                    type="text"
                    name="name"
                    label="name"
                    placeholder="Your dog's name"
                    className={
                      'peer block w-[98%] rounded-3xl border border-gray-300 bg-white text-center focus:border-[#B0CDD5] focus:ring-0 md:mx-1 md:w-[90%]'
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      props.setFieldValue('name', e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        props.handleSubmit()
                      }
                    }}
                  />
                  {props.errors.name && props.touched.name ? (
                    <div className="text-xs text-red-500">{props.errors.name}</div>
                  ) : null}
                </div>
                <div className="mt-3 flex w-full items-center justify-center">
                  <button
                    type="button"
                    className="mx-1 mb-4 flex w-full justify-center rounded-3xl border border-transparent bg-primary px-4 py-2 text-base font-medium hover:underline md:w-[90%] md:text-lg"
                    onClick={() => props.handleSubmit()}
                    style={{ color: '#fff' }}
                  >
                    Create Your Plan
                  </button>
                </div>
                <div className="pb-1 text-center text-xs">
                  <div className="flex flex-row justify-center">
                    <img className="mr-1" width={14} src={Clock} alt="Clock" />
                    <span>
                      It takes about <span className="font-bold">2 mins</span>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )
        }}
      </Formik>
    )
  }

  return (
    <>
      <div
        className="relative -mt-4 hidden h-16 w-full bg-cover bg-bottom bg-no-repeat lg:block"
        style={{
          backgroundColor,
          backgroundImage: topBanner ? `url(${topBanner})` : 'none'
        }}
      ></div>
      <div
        className="bg-image-none relative -mb-4 h-full w-full md:bg-cover md:bg-bottom md:bg-no-repeat"
        style={{ backgroundColor }}
      >
        <main className="mx-auto pb-8 flex h-full w-full flex-col md:pb-12 lg:container lg:flex-row 2xl:pb-20">
          <div className="mr-8 mt-8 hidden pt-8 lg:block lg:w-1/2">
            <div className="max-h-448 relative ml-5 h-448 w-4/5 rounded-lg md:mx-auto">
              <div
                className="image-backdrop-square-wrapper absolute h-448 w-full rounded-3xl"
                style={{ backgroundColor: mediaBackdropColor }}
              />
              {renderDesktopImage()}
            </div>
          </div>
          <div className="-mt-4 block w-full md:-mt-8 lg:hidden">
            <div className="relative block h-full w-full md:mx-auto lg:hidden">{renderMobileImage()}</div>
          </div>
          <div className="my-6 w-full lg:mt-8 lg:w-1/2 lg:pt-8">
            <div className="h3 block text-center text-3xl lg:mt-4 lg:text-5xl">{preFooterTextHeadlineOne}</div>
            <div className="h3 mt-2 mb-10 block text-center text-3xl lg:text-5xl">{preFooterTextHeadlineTwo}</div>
            <div className=" prose prose-indigo mx-auto w-4/5 text-teal-900 ">
              <p
                className={'enable-list-styles text-base text-center font-medium font-sans'}
                dangerouslySetInnerHTML={{ __html: preFooterTextOne }}
              ></p>
            </div>
            <div className="mx-auto mt-10 mb-8 lg:my-10"> {renderDogName()}</div>
            {getWidget()}
            {showRating && (
              <div className="text-center text-xs lg:text-base">
                <span className="font-bold">{ratingTextBold} </span>
                <span>
                  {ratingTextNormalOne} {renderRatingIcon()} {ratingTextNormalTwo}
                </span>
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  )
}

export default DogNamePreFooter
